<template lang="pug">

v-container(fluid)

  v-row.justify-center.align-center.doradociard
    v-col(cols=12 md=7 style="margin-bottom: 3em; margin-top: 3em")
      v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
      v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none
      //- v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none
      h2.text-center.font-weight-bold.mb-5.mt-2( v-html='arbitraje_normativa.titulo').d-none.d-md-block
      h3.text-center.font-weight-bold.mb-5.mt-2( v-html='arbitraje_normativa.titulo').text-center.d-md-none


      div(v-html='arbitraje_normativa.Subtitulo').text-center.d-none.d-md-block
      p.text-caption(v-html='arbitraje_normativa.Subtitulo' ).text-center.d-md-none

   
    v-col( cols=10 md=12).justify-center.align-center
      template( v-for='{titulo, vinculo} of arbitraje_normativa.normativas' ) 
        a.title.link-normativa( :href='vinculo' target='_blank' v-html='titulo' style="color: #003452").text-center.d-none.d-md-block
        a.link-normativa( :href='vinculo' target='_blank' v-html='titulo' style="color: #003452").text-center.d-md-none

  v-container.py-6.justify-center.align-center.mb-8
    v-row.justify-center.align-center
      template( v-for='{imagen, button, vinculo} of arbitraje_normativa.formatos' ) 
        v-col( cols=12 md=3)
       
            v-img( :src='imagen' ).d-none.d-md-block
            v-img( :src='imagen' height='150px' contain).d-md-none
            v-card-actions
              v-spacer
              v-btn( :href='vinculo' target='_blank' id="ar" outlined v-html='button').button.d-md-none
              v-btn( :href='vinculo' target='_blank' v-html='button' ).primary.d-none.d-md-flex
              v-spacer


      

</template>
<script>
export default {
  components: {
    bloque_arbitros: ()=>import('./bloque_arbitros')
  }
}
</script>
<style lang="sass">
.link-normativa
  text-decoration: none
  pointer
</style>
<style >
.colorazultexto{
  color: #003452;
}
.button#ar {
  background-color: transparent;
  color: #d19100;
  border:1px solid #d19100;
}
.button#ar:hover {
    

  background-color:#003452;
  color: #ffffff;

  border:1px solid #003452;
}
</style>